import { ThemeColor } from '../common/models/colors'

// Define all colors for the project in HSL format - all other colors are calculated from these:
export const baseColors = {
  // base
  text: [252, 42, 28],
  primary: [256, 88, 64],
  secondary: [0, 96, 64],
  // values
  tertiary: [208, 96, 64],
  quaternary: [252, 42, 48],
  quinary: [0, 96, 64],
  selected: [48, 92, 64],
  alternate: [92, 56, 64],
  warning: [24, 75, 35],
  // white & black
  lightest: [0, 0, 100],
  darkest: [0, 0, 0],
}

/**
 * Some components allow color customization. Since that's
 * optional, use this as a default fallback color in case
 * it's unset
 */
export const defaultColor: ThemeColor = 'primary'

export const defaultTextColor: ThemeColor = 'text'

/**
 * Calculate a color from a 3-number JS Array to a CSS hsl() function string
 * @param hslArray An array of 3 numbers representing hue, saturation & luminosity
 * @param changeLuminosityTo Optionally, change luminosity to a given value (0-100)
 * @returns eg. "hsl(45, 45, 45)"
 */
export const createColor = (
  hslArray: number[],
  changeLuminosityTo?: number
) => {
  return `hsl(${hslArray[0]}, ${hslArray[1]}%, ${
    changeLuminosityTo !== undefined ? changeLuminosityTo : hslArray[2]
  }%)`
}

/**
 * Create a HSLA color string including opacity from one of the existing colors
 * param colorKey Key of an item from the "colors" object, eg. "primary"
 * @param opacity Opacity to apply (range: 0-1), eg. 0.6
 * @returns eg. "hsla(45, 45, 45)"
 */
export const hsla = (color: string, opacity: number) => {
  const hslValues = color.substring(4, color.indexOf(')'))
  return `hsla(${hslValues}, ${opacity})`
}

export const hslToHex = (hsl: string) => {
  const sep = hsl.indexOf(',') > -1 ? ',' : ' '
  const hslColor: any = hsl.substring(4).split(')')[0].split(sep)

  const h = hslColor[0]
  const s = hslColor[1].substring(0, hslColor[1].length - 1)
  const l = hslColor[2].substring(0, hslColor[2].length - 1)

  const hDecimal = l / 100
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100
  const f = (n: number) => {
    const k = (n + h / 30) % 12
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0')
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export const hslToRGB = (hsl: string) => {
  const sep = hsl.indexOf(',') > -1 ? ',' : ' '
  const hslColor: any = hsl.substring(4).split(')')[0].split(sep)

  const h = hslColor[0]
  const s = hslColor[1].substring(0, hslColor[1].length - 1) / 100
  const l = hslColor[2].substring(0, hslColor[2].length - 1) / 100

  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = l - c / 2
  let r = 0
  let g = 0
  let b = 0

  if (0 <= h && h < 60) {
    r = c
    g = x
    b = 0
  } else if (60 <= h && h < 120) {
    r = x
    g = c
    b = 0
  } else if (120 <= h && h < 180) {
    r = 0
    g = c
    b = x
  } else if (180 <= h && h < 240) {
    r = 0
    g = x
    b = c
  } else if (240 <= h && h < 300) {
    r = x
    g = 0
    b = c
  } else if (300 <= h && h < 360) {
    r = c
    g = 0
    b = x
  }
  r = Math.round((r + m) * 255)
  g = Math.round((g + m) * 255)
  b = Math.round((b + m) * 255)

  return {
    r,
    g,
    b,
    rgb: 'rgb(' + r + ',' + g + ',' + b + ')',
  }
}

export const createColors = (
  baseColorSet: { [key: string]: number[] },
  overridePrimary?: boolean
) => {
  const baseColorSetConverted: object = {}
  Object.keys(baseColorSet).forEach(
    (key) => (baseColorSetConverted[key] = createColor(baseColorSet[key]))
  )

  return {
    ...baseColorSetConverted,
    text: createColor(baseColorSet.text),
    primary: createColor(baseColorSet.primary),
    secondary: createColor(baseColorSet.secondary),
    tertiary: createColor(baseColorSet.tertiary),
    selected: createColor(baseColorSet.selected),
    alternate: createColor(baseColorSet.alternate),
    quaternary: createColor(baseColorSet.quaternary),
    quinary: createColor(baseColorSet.quinary),
    darkest: createColor(baseColorSet.darkest),
    lightest: createColor(baseColorSet.lightest),
    // Calculated from the "darkest" color:
    dark: createColor(baseColorSet.darkest, 48),
    darker: createColor(baseColorSet.darkest, 12),
    // Calculated from the "lightest" color:
    light: createColor(baseColorSet.lightest, 84),
    lighter: createColor(baseColorSet.lightest, 92),
    // Calculated from the "text" color:
    textLightest: createColor(baseColorSet.text, 95),
    textLighter: createColor(baseColorSet.text, 84),
    text72: createColor(baseColorSet.text, 72),
    textLight: createColor(baseColorSet.text, 48),
    // Calculated from the "primary" color:
    primaryLight: createColor(
      overridePrimary ? baseColorSet.text : baseColorSet.primary,
      98
    ),
    primaryMild: createColor(
      overridePrimary ? baseColorSet.text : baseColorSet.primary,
      74
    ),
    primaryDark: createColor(
      overridePrimary ? baseColorSet.text : baseColorSet.primary,
      28
    ),
    // Faded primary colors
    primaryFadeLight: hsla(createColor(baseColorSet.primary), 0.15),
    primaryFadeHalf: hsla(createColor(baseColorSet.primary), 0.5),
    primaryFadeSemi: hsla(createColor(baseColorSet.primary), 0.8),
    // Calculated from the "secondary" color:
    secondaryLight: createColor(baseColorSet.secondary, 98),
    secondaryFade: createColor(baseColorSet.secondary, 94),
    secondaryDark: createColor(baseColorSet.secondary, 40),
    // Calculated from the "tertiary" color:
    tertiaryLight: createColor(baseColorSet.tertiary, 93),
    tertiaryDark: createColor(baseColorSet.tertiary, 40),
    // Calculated from the "selected" color:
    selectedLight: createColor(baseColorSet.selected, 92),
    selectedDark: createColor(baseColorSet.selected, 40),
    // Calculated from the "alternate" color:
    alternateLight: createColor(baseColorSet.alternate, 96),
    alternateDark: createColor(baseColorSet.alternate, 40),
    // Calculated from the "warning" color:
    warning: createColor(baseColorSet.warning),
    warningLight: createColor(baseColorSet.warning, 94),
    // Calculated from the "quaternary" color:
    quaternaryLight: createColor(baseColorSet.quaternary, 98),
    // Calculated from the "quinary" color:
    quinaryLight: createColor(baseColorSet.quinary, 98),
  }
}
