import React, { useEffect, useState, useReducer, Suspense } from 'react'
import Helmet from 'react-helmet'
import { Route, Switch } from 'react-router'
import { Redirect, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ThemeProvider } from 'styled-components'
import isEqual from 'lodash.isequal'
import { useTranslation } from 'react-i18next'

// Styling
import GlobalStyle from './styles'
import { baseColors, createColors } from './styles/colors'

// Utils
import { isClient } from './utils/getRenderPlatform'
import { IRoute, IRoutes, paths } from './constants/routes'
import {
  AppDispatchCtx,
  appReducer,
  AppStateCtx,
  DEFAULT_APP_STATE,
} from './context/AppContext'
import AsyncInitializer from './context/AsyncInitializer'

// Components
import DialogScreen from './components/DialogScreen'
import Notification from './components/Notification'

// Types
interface IProps {
  routes: IRoutes
}

const VERSION = process.env.VERSION || 'N.A.'
const COMMIT = process.env.COMMIT || 'N.A.'

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test' && isClient()) {
  console.info(`
  ░██████╗██╗░░░██╗███╗░░░███╗███╗░░░███╗
  ██╔════╝██║░░░██║████╗░████║████╗░████║
  ╚█████╗░██║░░░██║██╔████╔██║██╔████╔██║
  ░╚═══██╗██║░░░██║██║╚██╔╝██║██║╚██╔╝██║
  ██████╔╝╚██████╔╝██║░╚═╝░██║██║░╚═╝░██║
  ╚═════╝░░╚═════╝░╚═╝░░░░░╚═╝╚═╝░░░░░╚═╝
version: ${VERSION}
commit: ${COMMIT}
  `)
}

export const AppRoute = (route: IRoute) => {
  const { search, pathname } = useLocation()

  const [currentPath, setCurrentPath] = useState<string>()
  const [previousPath, setPreviousPath] = useState<string>()

  useEffect(() => {
    if (!currentPath) setCurrentPath(pathname)
    if (pathname !== currentPath) {
      setPreviousPath(currentPath)
      setCurrentPath(pathname)
    }
  }, [pathname])

  useEffect(() => {
    if (!window) return
    if (
      currentPath &&
      previousPath &&
      currentPath.split('/')[1] === previousPath.split('/')[1]
      // prevent window to scroll up, if just navigating/switching between tabs
    ) {
      if (previousPath.split('/').length > 2) {
        const pageWrapper = document.getElementById('intoView')
        if (!pageWrapper || !window) return
        window.scrollTo({
          top: pageWrapper?.getBoundingClientRect().top + window.scrollY - 88,
          behavior: 'smooth',
        })
      }
      return
    }
    window.scrollTo(0, 0)
  }, [currentPath, previousPath])

  const redirectAfterLogin = () => {
    const redirectTo = new URLSearchParams(search).get('redirectTo')
    if (redirectTo) {
      return redirectTo
    } else {
      return paths.personalProfile
    }
  }

  return (
    <Suspense fallback={<route.fallbackComponent />}>
      <Route
        path={route.path}
        render={
          // Redirect to login page if user is not logged in yet
          (props) => (
            <>
              <route.component {...props} />
              {!route.appRefreshToken &&
              (route.path === paths.externalFeedback ||
                route.path === paths.manifesto) ? null : (
                <>
                  {!route.appRefreshToken && route.path !== paths.signIn && (
                    <Redirect
                      to={`${paths.signIn}?redirectTo=${window.location.pathname}`}
                    />
                  )}
                  {route.path === paths.signIn && route.appRefreshToken && (
                    <Redirect to={redirectAfterLogin()} />
                  )}
                </>
              )}
            </>
          )
        }
      />
    </Suspense>
  )
}

const App = ({ routes }: IProps) => {
  const cookies = new Cookies()
  const { i18n } = useTranslation()

  const [state, dispatch] = useReducer(appReducer, { ...DEFAULT_APP_STATE })

  const refreshToken = cookies.get('refresh_token') || undefined

  const onSubmitPopup = () => {
    dispatch({
      type: 'updateAlertPopup',
      payload: undefined,
    })
  }

  const notification = state.notification

  useEffect(() => {
    if (!notification || !notification.show) return
    setTimeout(() => {
      dispatch({
        type: 'updateNotification',
        payload: {
          show: false,
          label: '',
        },
      })
    }, 4000)
  }, [notification])

  useEffect(() => {
    if (!state.company) return
    const companyColors = state.company.companyColors

    const newColors = {
      ...companyColors,
      text: companyColors.text || baseColors.text,
      primary: companyColors.primary || baseColors.primary,
      secondary: companyColors.secondary || baseColors.secondary,
      // values
      tertiary: companyColors.tertiary || baseColors.tertiary,
      quaternary: companyColors.quaternary || baseColors.quaternary,
      quinary: companyColors.quinary || baseColors.quinary,
      selected: companyColors.selected || baseColors.selected,
      alternate: companyColors.alternate || baseColors.alternate,
      warning: companyColors.warning || baseColors.warning,
      // white & black,
      lightest: baseColors.lightest,
      darkest: baseColors.darkest,
    }

    dispatch({
      type: 'updateTheme',
      payload: {
        id: 1,
        baseColors: newColors,
        colors: createColors(
          newColors,
          !isEqual(newColors.text, baseColors.text)
        ),
      },
    })
  }, [state.company && state.company.companyColors])

  useEffect(() => {
    if (!state.company || !state.company.language) return
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(state.company.language)
  }, [state.company && state.company.language])

  return (
    <AppStateCtx.Provider value={state}>
      <AppDispatchCtx.Provider value={dispatch}>
        <ThemeProvider theme={state.theme}>
          <Helmet titleTemplate="%s – SUMM" />
          {refreshToken && <AsyncInitializer />}
          {state.notification && state.notification.show && (
            <Notification label={state.notification.label} />
          )}
          {state.alertPopup && (
            <DialogScreen
              content={state.alertPopup}
              onSubmit={state.alertPopup?.onSubmit || onSubmitPopup}
              onDismiss={
                state.alertPopup.dismissButton || state.alertPopup.onDismiss
                  ? state.alertPopup.onDismiss || onSubmitPopup
                  : !(
                      state.alertPopup.onSubmit || state.alertPopup.actionButton
                    )
                  ? onSubmitPopup
                  : undefined
              }
              actionButton={
                state.alertPopup?.actionButton || 'general.actions.ok'
              }
            />
          )}
          <Switch>
            {routes.map((route: IRoute, i: number) => {
              // Only pass allowed route props to Route component
              const { ...routeProps } = route
              return (
                <AppRoute
                  key={i}
                  appRefreshToken={refreshToken}
                  {...routeProps}
                />
              )
            })}
          </Switch>
          <GlobalStyle />
        </ThemeProvider>
      </AppDispatchCtx.Provider>
    </AppStateCtx.Provider>
  )
}

export default App
